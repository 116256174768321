import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import {getAuth, signInAnonymously} from "firebase/auth"

// import "react-toastify/dist/ReactToastify.css"
// import {ToastContainer, toast} from 'react-toastify'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import toast from "react-hot-toast";
import { getAccountInfo, updateUser } from "services/auth";

import { FIREBASE_TOKEN } from "constants/auth.js"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//const analytics = getAnalytics(app);

export const NotifyFirebase = async () => {
  let user = await getAccountInfo()

  user = user?.data

  if(!user) {
    return;
  }

  if(Notification.permission === "granted") {
    if(!user?.firebase_token) {
      const token = localStorage.getItem(FIREBASE_TOKEN)

      if(token) {
        await updateUser({ "firebase_token": token });
      }
    }

    return;
  }

  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js")

      console.log(
        "Service Worker registered with scope:",
        registration.scope
      );
    } catch(e) {
        console.error("Service Worker registration failed:", e);
    }
  }
  // const singUpAnonymously= ()=>{
  //   signInAnonymously(getAuth(app)).then(user=> console.log(user));
  // }
  const firebaseConfig = {
    apiKey: "AIzaSyC-WrnTOfSUB0VeMBbQefsP_AuYQWc0Dd8",
    authDomain: "medibear-aws.firebaseapp.com",
    projectId: "medibear-aws",
    storageBucket: "medibear-aws.appspot.com",
    messagingSenderId: "848170161309",
    appId: "1:848170161309:web:54aa5de1ec2ad07f4c7074",
    measurementId: "G-Y2LW6SDWTW",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging(app);
  const activateMessages = async () => {
    let permission = Notification.permission;

    if(permission === 'denied') {
      return;
    }

    if(permission !== 'granted') {
      permission = await Notification.requestPermission()

      if(permission !== 'granted') {
        return;
      }
    }

    let token = user["firebase_token"]

  //  if(!token) {
      token = await getToken(messaging, {
        vapidKey:
          "BCZZh6y5vkV6lBlh20KIzIIVQzqBsRg8kEYTBWNjdTKbNFXcJTRRi-N9U-kOA-FFYC_6kHB0GUL-KijrcKYKdaE",
      }).catch((error) => console.log("error generating token: " + error));
  //  }

    if (token !== user?.firebase_token) {
      console.log("Token: ", token);
      localStorage.setItem(FIREBASE_TOKEN, token)

      await updateUser({ "firebase_token": token });
    }
    if (!token) console.log("no token");
  };

  // singUpAnonymously();
  await activateMessages();
  onMessage(messaging, (message) => {
    console.log("your message: ", message);

    // toast(message.notification.title)
    toast(message["data"]["pinpoint.notification.title"], {
      position: "bottom-right",
      duration: 5000,
      style: {
        border: "1px solid #333",
        padding: "16px",
        color: "#fff",
        background: "#333",
      },
      iconTheme: {
        primary: "#fff",
        secondary: "#333",
      },
    });
    // console.log("payload.data.url"+message.data.url)
    //window.location.replace("http://localhost:3000/"+message.data.url)
  });
};
