import { parseToUrlParams } from "../utils";
import { api } from "./api";

export const getAllCategories = async () =>
  await api.get("/ordination/exam/category");

export const getAllExamTypes = async () =>
  await api.get("/ordination/exam/type");

export const getAllExamSpecies = async () =>
  await api.get("/ordination/exam/specie");

export const getAllExams = async () =>
  await api.get("/ordination/exam")


export const getExamById = async (id) =>
  await api.get(`/ordination/exam/?id=${id}`)

export const createExam = async (body) =>
  await api.post("/exam", body);


export const updateExam = async (body) =>
await api.put("/exam", body);
