// utils
import { lazy, Suspense, useState } from 'react';

// components
import ScrollProgress from '@ui/ScrollProgress';
import Panel from '@layout/Panel';
import Sidebar from '@layout/Sidebar';
import {Navigate, Route, Routes} from 'react-router-dom';
import BottomMenu from '@layout/BottomMenu';
import WidgetsLoader from '@components/WidgetsLoader';

import NotificationPopup from "components/AllowNotificationsPopup"

// hooks
import useWindowSize from '@hooks/useWindowSize';
import usePageIsOverflow from '@hooks/usePageIsOverflow';
import {useRef, useEffect} from 'react';

// pages
const PatientAppointments = lazy(() => import('@pages/PatientAppointments'));
const Finances = lazy(() => import('@pages/Finances'));
const Settings = lazy(() => import('@pages/Settings'));
const PageNotFound = lazy(() => import('@pages/PageNotFound'));
const Doctors = lazy(() => import('@pages/Doctors'));
const DashboardA = lazy(() => import('@pages/DashboardA'));
const Tests = lazy(() => import('@pages/MedicalExams'));
const Patients = lazy(() => import('@pages/Patients'));
const AppointmentsList =  lazy(() => import('@pages/UppcomingAppointments'));

const AppLayout = () => {
  const [askForNotification, setAskForNotification] = useState(Notification.permission !== 'granted')
    const appRef = useRef(null);
    const isOverflow = usePageIsOverflow();
    const {width} = useWindowSize();
    const isMobile = width < 768;

    useEffect(() => {
        if (appRef.current) {
            appRef.current.scrollTo(0, 0);
        }
    }, []);

    return (
        <div className="app" ref={appRef}>
            {isOverflow ? <ScrollProgress/> : null}
            <NotificationPopup setOpen={setAskForNotification} open={askForNotification} handler={() => {
              setAskForNotification(false)
          }} />
            <Sidebar/>
            <div className="app_content">
                <Panel/>
                <Suspense fallback={<WidgetsLoader />}>
                    <Routes>
                        <Route path="/" element={<Navigate to="/kontrolna-tabla"/>}/>
                        <Route path="/kontrolna-tabla" element={<DashboardA/>}/>
                       
                        <Route path="/kalendar" element={<PatientAppointments/>}/>
                        <Route path="/pacijenti" element={<Patients/>}/>
                        <Route path="/pregledi" element={<Tests/>}/>
                        <Route path="/zakazani-pregledi" element={<AppointmentsList/>}/>
                        <Route path="/doktori" element={<Doctors/>}/>
                        <Route path="/finances" element={<Finances/>}/>
                        <Route path="/postavke" element={<Settings/>}/>
                        <Route path="/404" element={<PageNotFound/>}/>
                      {/* problem sa aws amplify, ne radi nesto a nemamo puno vremena fixat:)
                      <Route path="*" element={<Navigate to="/404" replace />}/>
                      */}
                    </Routes>
                </Suspense>
            </div>
            {isMobile ? <BottomMenu/> : null}
        </div>
    )
}

export default AppLayout;
