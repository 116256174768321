import { parseToUrlParams } from "utils/index";
import { api } from "./api";
export const getAllDoctors = async (ordinationId, params) => {
    if (ordinationId === 'allOrdinations') {
        return await api(
            `/profile/doctors/?${parseToUrlParams({
                limit: 100,
                offset: 0,
                ...params,
            })}`
        );
    }
    return await api(
        `/profile/ordination-doctor/?ordination=${ordinationId}&${parseToUrlParams({
            limit: 100,
            offset: 0,
            ...params,
        })}`
    );
}

export const getSpecializations = async () =>
  await api(`/specialization/?limit=0`);
  export const addDoctor = async (body) =>
  await api.post("/ordination/doctor", {"id":body});

export const getDoctor = async (id) => {
  
  let resp = await api(`/profile/doctor/?id=${id}`);
  console.log(resp)
  let result =  resp?.data?.results
  console.log(result)
  return result ? result : null
}

// export const getDoctor = async (id) => {
//   return new Promise((resolve, reject) => {
//     const response = {
//       last_name: "Mustur",
//       first_name: "Dušan",
//       specializations: [
//         {
//           id: 7,
//           name: "Reumatologija",
//         },
//         {
//           id: 8,
//           name: "Fizijatrija",
//         },
//       ],
//       id: 251,
//       ordinations: [
//         {
//           website: "https://www.normedica.me/",
//           place_id: "ChIJv2nyGno7TBMR5_ZrJ7sObI0",
//           total_score: 5,
//           address: "19 Stijepa Šarenca",
//           email: "info@normedica.me",
//           name: "Normedica",
//           city: "Herceg Novi",
//           image: "/ordinations/Normedica.jpg",
//           short_name: "Normedica",
//           phone_number: "+382 68 309 309",
//           reviews_count: 3,
//           id: 79,
//         },
//       ],
//       title: "Doc. Dr",
//     };
//     setTimeout(() => {
//       resolve(response);
//     }, 3000);
//   });
// };
