import axios from "axios";
// import { refresh_cookies } from "./auth";
import { Auth } from 'aws-amplify';

axios.defaults.baseURL =
    "https://api.medibear.me";


//axios.defaults.withCredentials = true
axios.interceptors.request.use(
    async (config) => {
        // const cookie = localStorage.getItem("cookie");

        // if (cookie) {
        config.headers = {
            ...config.headers,
            Authorization:`Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`
        };
        // }

        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error?.config;

        if ((error?.response?.status === 401 || error?.response?.status === 403) && !config?.sent) {
            config.sent = true;
            return axios(config);
        }
        return Promise.reject(error);
    }
);

export const IMAGE_ROUTE_URL = "https://d17onk42adk2w8.cloudfront.net";

export const api = axios;