import { createContext, useContext, useEffect, useState } from 'react';
import { getProfileInfo } from 'services/auth';

export const userContext = createContext(undefined);


export const UserContextApi = ({ children }) => {
  const [user, setUser] = useState(null);

  const refreshUser = () => {
    // Ovdje možete ponovno pozvati getProfileInfo kako biste ažurirali korisničke podatke
    getProfileInfo().then((userData) => {
        setUser(userData?.data?.results ? userData?.data?.results[0] : {});
    });
  };

  useEffect(() => {
    // Ovdje možete asinkrono dohvatiti korisničke podatke
    getProfileInfo().then((userData) => {
        setUser(userData?.data?.results ? userData?.data?.results[0] : {});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <userContext.Provider
      value={{
        user,
        refreshUser, // Dodali smo funkciju za osvježavanje korisnika
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export const useUserContext = () => useContext(userContext);
