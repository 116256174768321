import { createContext, useContext, useEffect, useState } from 'react';
import { getAllExamTypes, getAllExamSpecies, getAllExams } from 'services/exams';

export const ExamContext = createContext(undefined);

export const ExamApi = ({ children }) => {
  const [examTypes, setExamTypes] = useState([]);
  const [examSpecies, setExamSpecies] = useState([]);
  const [exams, setExams] = useState([]);

  const refreshExamTypes = async () => {
    const typeData = await getAllExamTypes();
    setExamTypes(typeData.data.results);
  };

  const refreshExamSpecies = async () => {
    const specieData = await getAllExamSpecies();
    setExamSpecies(specieData.data.results);
  };

  const refreshExams = async () => {
    const examData = await getAllExams();
    setExams(examData.data?.results || []);
  };

  useEffect(() => {
    refreshExamTypes();
    refreshExamSpecies();
    refreshExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExamContext.Provider
      value={{
        examTypes,
        examSpecies,
        exams,
        refreshExamTypes,
        refreshExamSpecies,
        refreshExams
      }}
    >
      {children}
    </ExamContext.Provider>
  );
};

export const useExamContext = () => useContext(ExamContext);