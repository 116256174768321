import { createContext, useContext, useEffect, useState } from 'react';
import { getAllDoctors } from 'services/doctors'; // Pretpostavka da imate ovu funkciju
import { useUserContext } from './userContext';

// Doctor Context
export const DoctorContext = createContext(undefined);

export const DoctorApi = ({ children }) => {
  const [doctors, setDoctors] = useState([]);
  const { user } = useUserContext();

  const refreshDoctors = async () => {
    try {
      const doctorData = await getAllDoctors(user.id);
      setDoctors(doctorData.data.results[0]["doctors"]);
    } catch (error) {
      console.error("Error fetching doctors:", error);
    }
  };

  useEffect( () => {
    const refreshUserEffect = async () => {
      if (user?.id) {
        await refreshDoctors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
    }
    refreshUserEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <DoctorContext.Provider
      value={{
        doctors,
        refreshDoctors
      }}
    >
      {children}
    </DoctorContext.Provider>
  );
};

export const useDoctorContext = () => useContext(DoctorContext);
