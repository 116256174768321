import { createContext, useContext, useEffect, useState } from 'react';
import { getAllPatients } from 'services/patients'; // Pretpostavka da imate ovu funkciju

// Patient Context
export const PatientContext = createContext(undefined);

export const PatientApi = ({ children }) => {
    const [patients, setPatients] = useState([]);

    const refreshPatients = async () => {
        try {
            const patientData = await getAllPatients();
            setPatients(patientData.data.results);
        } catch (error) {
            console.error("Error fetching patients:", error);
        }
    };

    useEffect( () => {
        const refreshUserEffect = async () => {
            await refreshPatients();
        }
        refreshUserEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PatientContext.Provider
            value={{
                patients,
                refreshPatients
            }}
        >
            {children}
        </PatientContext.Provider>
    );
};

export const usePatientContext = () => useContext(PatientContext);
