// styling
import styled from 'styled-components/macro';
import React, { useState } from "react";

// components
import {Badge} from '@ui/Badge/style';

// assets
import placeholder from '@assets/placeholder.jpg'
import placeholderClinic from '@assets/avatars/klinika_placeholder.svg';
import placeholderDoctor from '@assets/avatars/ljekar_placeholder.svg';
import PropTypes from 'prop-types';

const placeholderMap = {
  default: placeholder,
  clinic: placeholderClinic,
  doctor: placeholderDoctor
  // "Type": placeholderType,
};
// utils

const Container = styled.div`
  width: ${props => `${props.size}px`};
  aspect-ratio: 1 / 1;
  position: relative;
  
  .indicator {
    position: absolute;
    right: -6px;
    top: -6px;
    z-index: 2;
  }
`
const Img = styled.picture`
  img {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
`

const Avatar = ({avatar, alt, size, online = false, type='default'}) => {
  const placeholderSrc = placeholderMap[type] ;
  const [initiateFallback, setInitiateFallback] = useState(false);

    return (
        <Container size={size ? size : 40} className="avatar">
            <Img>
            {/*
                <source srcSet={avatar ? avatar.webp : placeholderSvgClinic} type="image/webp"/>
                <source srcSet={avatar ? avatar.jpg : placeholderSvgClinic} type="image/jpeg"/>
                */
            }
                <img
                    src={avatar?.jpg ? (initiateFallback ? placeholderSrc : avatar.jpg) : placeholderSrc}
                    alt={alt}
                    width="100%"
                    height="100%"
                    onError={(e) => {
                            setInitiateFallback(true);
                        }
                    }
                />
            </Img>
            {online ? <Badge color="green" className="indicator" /> : null}
        </Container>
    )
}

Avatar.propTypes = {
    avatar: PropTypes.object,
    alt: PropTypes.string.isRequired,
    online: PropTypes.bool,
    size: PropTypes.number
}

export default Avatar;