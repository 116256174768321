import React, { useState } from 'react';

import { Container } from './style';

import Btn from '@ui/Btn';

import ModalWindow from '@components/ModalWindow';

import { NotifyFirebase } from "hooks/NotifyFirebase"

const NotificationPopup = ({ open, setOpen, handler }) => {
    const [showButton, setShowButton] = useState(Notification.permission !== "denied")

  const notifHandler = async () => {
      await NotifyFirebase()

    if(Notification.permission === 'granted') {
      setOpen(false)
    }

    if(Notification.permission === "denied" && showButton) {
      setShowButton(false)
    }
  }

    return (
        <ModalWindow isVisible={open} visibilityHandler={handler}>
            <Container className={open ? 'visible' : ''}>
              <div className="infos">
                <p>Da biste nastavili koristiti ovaj sajt, potrebno je da <br /> <strong>omogućite notifikacije.</strong></p>
                <p>Notifikacije su obavezne jer ćete putem njih dobijati informacije o terminima i ostale važne obavijesti koje su ključne za korištenje naših usluga.</p>
                <p className='title'><strong>Hvala na razumijevanju.</strong></p>
              </div>
              {showButton && 
              <Btn text="Dozvoli notifikacije" handler={notifHandler} />
              }
            </Container>
        </ModalWindow>
    );
}

export default NotificationPopup;
