import { createContext, useContext, useEffect, useState } from 'react';
import { getAllCategories } from 'services/exams';

// Category Context
export const CategoryContext = createContext(undefined);

export const CategoryApi = ({ children }) => {
  const [categories, setCategories] = useState([]);

  const refreshCategories = async () => {
    const catData = await getAllCategories();
    setCategories(catData.data.results);
  };

  useEffect(() => {
    refreshCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CategoryContext.Provider
      value={{
        categories,
        refreshCategories
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategoryContext = () => useContext(CategoryContext);