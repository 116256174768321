import styled from 'styled-components/macro';
import { breakpoints } from '@styles/vars'
import theme from 'styled-theming';

const bg = theme('theme', {
    light: '#fff',
    dark: '#2a2f32'
});

export const Container = styled.div`
  position: absolute;
  width: 300px;  
  background: ${bg};
  box-shadow: 0 1px 16px rgba(20, 46, 110, 0.4);
  border-radius: 8px;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity var(--transition);
  opacity: 0;
  visibility: hidden;

  ${breakpoints.mobileS} {
    width: 300px;
  }

  ${breakpoints.mobileM} {
    width: 350px;
  }

  ${breakpoints.mobileL} {
    width: 395px;
  }
  
  ${breakpoints.tablet} {
    width: 540px;
  }



  &.visible {
    opacity: 1;
    visibility: visible;
  }

  .infos {
    display: flex;

    flex-direction: column;
    align-items: center;
    gap: 20px;

    p {
      text-align: center;
    }

    .title {
      font-size: 1.2em;
    }

    .thanks {
      font-weight: 500;
    }
  }

  button {
    margin-top: 15px;
  }

`;
