// styles
import GlobalStyles from '@styles/global';
import 'react-grid-layout/css/styles.css';
import './fonts/icomoon/style.css';
import 'swiper/css';
import 'swiper/css/effect-fade';

// components
import AppLayout from './AppLayout';
import {SnackbarProvider} from 'notistack';

// utils
import {ThemeProvider, StyleSheetManager} from 'styled-components';
import {ThemeProvider as MuiThemeProvider, createTheme} from '@mui/material/styles';
import {preventDefault} from '@utils/helpers';
import rtlPlugin from 'stylis-plugin-rtl';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';

// contexts
import {SidebarContextAPI} from '@contexts/sidebarContext';
import {UserContextApi} from '@contexts/userContext';
import { CategoryApi } from '@contexts/categoryContext';
import { ExamApi } from '@contexts/examContext';


import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

// hooks
import {useEffect} from 'react';
import {useInterfaceContext} from '@contexts/interfaceContext';
import {useDispatch} from 'react-redux';

// actions
import {saveToLocalStorage} from '@store/features/layout';
import { DoctorApi, DoctorContext } from '@contexts/doctorContext';
import { PatientApi } from '@contexts/patientContext';
import {Amplify, Auth } from 'aws-amplify';
// import awsmobile  from './aws-exports';

import { NotifyFirebase } from "hooks/NotifyFirebase"

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:9dc8af9f-7727-456b-ac9c-de0510af92f9",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_JznEVCExs",
    "aws_user_pools_web_client_id": "4006f22n2sdgrbq52v4gc3crc9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};

// Configure Amplify in index file or root file
Amplify.configure(awsmobile)
const App = () => {
    const page = document.documentElement;
    const {isDarkMode, isContrastMode, direction} = useInterfaceContext();
    const theme = createTheme({
        direction: direction,
    });
    const cacheRtl = createCache({
        key: 'css-rtl',
        stylisPlugins: [rtlPlugin],
    });

    useDispatch()(saveToLocalStorage());

    useEffect(() => {
        page.setAttribute('dir', direction);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [direction]);

    useEffect(() => {
        NotifyFirebase()

        isContrastMode && page.classList.add('contrast');
        preventDefault();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CacheProvider value={cacheRtl}>
            <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={{theme: isDarkMode ? 'dark' : 'light'}}>
                    <SnackbarProvider maxSnack={3}
                                      anchorOrigin={{
                                          vertical: 'top',
                                          horizontal: direction === 'ltr' ? 'right' : 'left',
                                      }}
                                      autoHideDuration={3000}
                    >
                        <UserContextApi>
                        <DoctorApi>
                        <PatientApi>
                        <CategoryApi>
                        <ExamApi>
                        <SidebarContextAPI>
                            <GlobalStyles/>
                            <StyleSheetManager stylisPlugins={direction === 'rtl' ? [rtlPlugin] : []}>
                              <AppLayout/>
                            </StyleSheetManager>
                        </SidebarContextAPI>
                        </ExamApi>
                        </CategoryApi>
                        </PatientApi>
                        </DoctorApi>
                        </UserContextApi>
                    </SnackbarProvider>
                </ThemeProvider>
            </MuiThemeProvider>
        </CacheProvider>
    );
}

export default withAuthenticator(App);
